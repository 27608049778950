import { css } from "@emotion/react";
import type { Property } from "csstype";
import type { ReactNode } from "react";

import type { WithResponsive } from "../../utils/media-queries";
import { generateResponsiveCSS } from "../../utils/media-queries";

export interface FlexProps {
  alignItems?: WithResponsive<Property.AlignItems>;
  children?: ReactNode;
  className?: string;
  display?: WithResponsive<Property.Display>;
  flexDirection?: WithResponsive<Property.FlexDirection>;
  flexWrap?: WithResponsive<Property.FlexWrap>;
  gap?: WithResponsive<Property.Gap>;
  justifyContent?: WithResponsive<Property.JustifyContent>;
  margin?: WithResponsive<Property.Margin>;
  maxWidth?: WithResponsive<Property.MaxWidth>;
  padding?: WithResponsive<Property.Padding>;
  width?: WithResponsive<Property.Width>;
}

function Flex({ children, className, ...styles }: FlexProps) {
  const cssString = generateResponsiveCSS({ ...styles });
  return (
    <div
      className={className}
      css={css`
        display: flex;
        ${cssString}
      `}
    >
      {children}
    </div>
  );
}

export default Flex;
